/* PlatformCard.css

.platform-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .platform-card:hover {
    transform: scale(1.05);
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .platform-info {
    display: flex;
    flex-direction: row;
  }

  
  .info-container {
    flex: 1;
  }
  
  .platform-name {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .platform-logo img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .connect-button {
    text-align: right;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
   */


   .platform-name {
    max-width: 70%; /* Set the maximum width to 100% */
    overflow: hidden; /* Hide any overflow text */
    white-space: nowrap; /* Prevent the text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
  }