// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

#root {
  display: contents;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--bs-body-bg) !important;
}

.ck.ck-toolbar {
  background-color: var(--bs-body-bg) !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  color: var(--bs-heading-color) !important;
}

.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__panel .ck-list__item .ck .ck-button__label  {
  color: var(--bs-heading-color) !important;
  
}

.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__panel .ck-list__item .ck.ck-button {
  background-color: var(--bs-body-bg) !important;
  cursor: pointer;
}

