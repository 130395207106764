/* for pricing page use prefix 'psp' */

.pricing-tab-container {
  /* width: 300px; */
  /* margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif; */
}

.psp-tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  width: 40%;
  justify-self: center;
  box-shadow: 2px 2px 5px #0005;
}

.psp-tab {
  flex: 1;
  padding: 10px 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 16px;
}

.psp-tab.psp-active {
  color: #007bff;
  font-weight: bold;
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 50%;
  background-color: #007bff;
  transition: transform 0.3s;
}

.tab-indicator.month {
  transform: translateX(0%);
}

.tab-indicator.annual {
  transform: translateX(100%);
}

.content {
  margin-top: 20px;
}

.plan-details {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .psp-tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    justify-self: center;
    box-shadow: 2px 2px 5px #0005;
  }

  .psp-tab {
    flex: 1;
    padding: 3px 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .psp-tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 50%;
    justify-self: center;
    box-shadow: 2px 2px 5px #0005;
  }

  .psp-tab {
    flex: 1;
    padding: 10px 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 12px;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .psp-tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 55%;
    justify-self: center;
    box-shadow: 2px 2px 5px #0005;
  }

  .psp-tab {
    flex: 1;
    padding: 8px 12px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 12px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .psp-tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    justify-self: center;
    box-shadow: 2px 2px 5px #0005;
  }

  .psp-tab {
    flex: 1;
    padding: 8px 14px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 12px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .psp-tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 460px;
    justify-self: center;
    box-shadow: 2px 2px 5px #0005;
  }

  .psp-tab {
    flex: 1;
    padding: 10px 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 16px;
  }
}
