.rdt_TableHeader {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

.rdt_TableHeadRow {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

.rdt_TableRow {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

.rdt_TableBody {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

.rdt_Pagination {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}
.customActive {
    box-shadow: 3px  3px 10px 2px #0005 !important;
}

.customActive::after{
    content:" ";
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--bs-primary);
    position: relative;
    animation-name: changetab;
    animation-duration: .5s;
    animation-timing-function: ease-in-out ;
}

.customTabBody {
    width: 100%;
    // animation-name: customTabBodyAnimation;
    // animation-timing-function: ease-in-out;
    // animation-duration: 0.5s;
    // transition-timing-function: linear;
    // animation-direction: alternate;
    // position: relative;
}

@keyframes customTabBodyAnimation {
    from {
        left: -100%;
    }

    to {
        left:0;
    }
}


@keyframes changetab {
    from {
        width: 0px;
        box-shadow: none;
    }
    to{
      width: 100%;
    }
}

.customEditorWrapper {
    padding: 0px !important;
    .ck-editor{
        padding: 0px;
        height: 100% !important;
        .ck-editor__main {
            padding: 0px !important;
            height: 100% !important;
           .ck-editor__editable {
                height: 48vh !important;
            }
        }
    }
}

.ck .ck-powered-by {
    display: none !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0d6efd !important;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black !important;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.custmd {
    background-color: #0006;
}